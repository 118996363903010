.morph
    //position: absolute
    //bottom: 48px
    //left: 50%
    //transform: translateX(-50%)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    transition: $transitionNorm opacity
    opacity: 0
    +tab
        margin-top: 50px
    +mob
        margin-top: 50px
        flex-direction: row
    &.active
        opacity: 1
    span
        font-size: 16px
        font-weight: 600
        line-height: 24px
        color: #4095F2
        display: block
        margin-top: 10px
        +mob
            margin-top: 0
            margin-left: 13px
            font-size: 14px
    .icon
        position: relative
        width: 100px
        height: 100px
        display: flex
        justify-content: center
        align-items: center
        +tab
            width: 70px
            height: 70px
        +mob
            width: 40px
            height: 40px
        &:after
            content: ""
            display: block
            position: absolute
            width: 50%
            height: 50%
            top: 25%
            left: 25%
            border-radius: 100%
            background-color: white
            box-shadow: 0 0 10px 10px #FFFFFF
            z-index: 5
            +tab
                width: 40%
                height: 40%
                top: 30%
                left: 30%
            +mob
                width: 30%
                height: 30%
                top: 35%
                left: 35%
        img
            width: 25px
            z-index: 6
            +tab
                width: 20px
            +mob
                width: 14px
        .layers
            > div
                background-color: dodgerblue
                opacity: 0.5
                width: 100%
                height: 100%
                top: 0
                left: 0
                position: absolute
                background: linear-gradient(#4095f2, transparent), linear-gradient(to top left, lime, transparent), linear-gradient(to top right, #5400ff, transparent), linear-gradient(to bottom left, #0040ff, transparent), linear-gradient(to bottom right, #0012ff, transparent)
                background-blend-mode: screen
                &:nth-child(1)
                    z-index: 1
                    animation: morph1 3000ms linear infinite alternate,spin1 26s linear infinite reverse
                &:nth-child(2)
                    width: 90%
                    height: 90%
                    top: 5%
                    left: 5%
                    z-index: 2
                    animation: morph2 2000ms linear infinite alternate,spin2 26s linear infinite reverse
                &:nth-child(3)
                    width: 80%
                    height: 80%
                    top: 10%
                    left: 10%
                    z-index: 3
                    animation: morph3 1000ms linear infinite alternate,spin1 26s linear infinite reverse
                &:nth-child(4)
                    width: 70%
                    height: 70%
                    top: 15%
                    left: 15%
                    z-index: 4
                    animation: morph4 700ms linear infinite alternate,spin2 26s linear infinite reverse
