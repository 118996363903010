@keyframes morph1
    0%
        border-radius: 40% 60%
    to
        border-radius: 40% 60% 60% 40%/70% 30%

@keyframes morph2
    0%
        border-radius: 40% 60% 60% 40%/70% 30%
    to
        border-radius: 70% 30%

@keyframes morph3
    0%
        border-radius: 70% 30%
    to
        border-radius: 70% 30% 30% 70% / 60% 40%

@keyframes morph4
    0%
        border-radius: 60% 40%
    to
        border-radius: 60% 40% 40% 60% / 70% 36% 64% 30%

@keyframes spin1
    to
        transform: rotate(1turn)

@keyframes spin2
    to
        transform: rotate(-1turn)

@keyframes ripple-green
    50%
        background-color: #D6FFFC
        border-color: #2AB8AF
    to
        background-color: inherit
        border-color: inherit

@keyframes ripple-red
    50%
        background-color: #FFE1E1
        border-color: #F56D6D
    to
        background-color: inherit
        border-color: inherit

@keyframes blink
    50%
        transform: translate(-50%, -50%) scale(1.8)
        opacity: 0
    to
        transform: translate(-50%, -50%) scale(1.8)
        opacity: 0

@keyframes tap
    50%
        opacity: 0.7
    to
        opacity: 1