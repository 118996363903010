#answer
    .task_2_2
        text-align: center
        .subtitle
            font-size: 16px
            font-weight: 400
            margin-bottom: 20px
        .subtask
            display: none
            flex-wrap: wrap
            width: 520px
            +tab
                padding: 0
                width: 40vh
                width: 40dvh
                height: 40vh
                height: 40dvh
            +mob
                width: 100%
                padding: 0
                gap: 2%
            &.active
                display: flex
            .grid-cell
                height: 64px
                border: 1.5px solid #4095F2
                display: flex
                justify-content: center
                align-items: center
                flex: 12%
                margin: 12px
                border-radius: 16px
                color: #3E3E3E
                font-size: 32px
                font-weight: 600
                user-select: none
                cursor: pointer
                +tab
                    height: auto
                    font-size: 2.4vh
                    font-size: 2.4dvh
                    border-radius: 1.5vh
                    border-radius: 1.5dvh
                    margin: 1vh
                    margin: 1dvh
                +mob
                    flex: 18%
                    height: 16vw
                    margin: 0
                    margin-bottom: 2%
                    font-size: 24px
                &:hover
                    background-color: #F2F8FF
                &.correct
                    background: #F2F8FF
                    box-shadow: 0 0 0 0 green
                    animation: ripple-green 500ms ease-in-out forwards
                &.wrong
                    background: #F2F8FF
                    box-shadow: 0 0 0 0 red
                    animation: ripple-red 500ms ease-in-out forwards

