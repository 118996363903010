/////
// Page width for A4 = 714px (96DPI 794px - 80px margin from left and right sides)
/////
.page.page-pdf
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    //margin-top: 160px
    color: #4D4D4D
    background: #ffffff
    .header
        height: 96px
        margin-bottom: 64px
        background: #FFFFFF
        .logo
            top: 31px
            img
                height: 32px
    .block
        display: none
    .sections
        width: 100%
        max-width: 1136px
        padding: 0
    .section
        position: relative
        width: 100%
        background: #FFFFFF
        padding: 28px 14px
        .title
            position: relative
            font-size: 20px
            font-weight: 600
            line-height: 48px
            color: #4D4D4D
            z-index: 1
        //margin-bottom: 16px
        >.title
            color: #4D4D4D
        .descr
            position: relative
            font-size: 16px
            font-weight: 400
            line-height: 32px
            color: #2A2A2B
            white-space: pre-line
            z-index: 1
        .button
            margin-top: 40px
            position: relative
            z-index: 1
            &:not(:last-child)
                margin-right: 8px
        .sep
            position: relative
            width: 100%
            height: 1px
            background-color: #D3D3D3
            margin-top: 20px
            margin-bottom: 20px

        &.cover
            padding-top: 8px
            padding-bottom: 40px
            display: flex
            justify-content: center
            align-items: center
            img
                height: 54px

        &.top, &.state
            margin-top: 0
            padding-top: 0
            background-color: #FFFFFF
            overflow: hidden
            display: flex
            align-items: flex-start

            .scale
                position: relative
                display: none
                width: 100%
                height: 12px
                border-radius: 6px
                overflow: hidden
                background: #F2F2F2
                margin-top: 8px
                margin-bottom: 16px
                .line
                    position: absolute
                    top: 0
                    left: 0
                    height: 100%
                    background: #2AB8AF
                    border-radius: 6px
                &.high
                    .line
                        background: #2AB8AF
                &.norm
                    .line
                        background: #2AB8AF
                &.attention
                    .line
                        background: #FFB82E
                &.low
                    .line
                        background: #F56D6D
            .graph
                position: relative
                width: 185px
                height: 190px
                margin-left: 34px
                flex-shrink: 0
                display: flex
                justify-content: center
                align-items: center
                svg
                    width: 210px
                img
                    position: absolute
                    width: 54px
                    height: 54px
                    top: 60px
                    left: 50%
                    transform: translateX(-50%)
                span
                    position: absolute
                    top: 125px
                    left: 50%
                    font-size: 18px
                    font-weight: 600
                    line-height: 20px
                    transform: translateX(-50%)
                    text-align: center
                    color: #2AB8AF
                &.high
                    span
                        color: #2AB8AF
                    svg
                        path:last-child
                            stroke: #2AB8AF
                &.norm
                    span
                        color: #2AB8AF
                    svg
                        path:last-child
                            stroke: #2AB8AF
                &.attention
                    span
                        color: #FFB82E
                    svg
                        path:last-child
                            stroke: #FFB82E
                &.low
                    span
                        color: #F56D6D
                    svg
                        path:last-child
                            stroke: #F56D6D


            .title
                color: #4D4D4D
                font-size: 20px
                font-weight: 600
                line-height: 48px
            .descr
                color: #2A2A2B
                font-size: 14px
                font-weight: 400
                line-height: 20px

        &.state
            flex-direction: column
            .wrapper
                display: flex
                padding-bottom: 30px
                border-bottom: 1px solid #E8F3FF
                margin-bottom: 24px
                .info
                    width: 50%
                .right
                    width: 50%
                    display: flex
                    justify-content: center
            .detailsState
                .descr
                    column-count: 2
                    column-gap: 16px
                    white-space: pre-line
                    .par
                        &:not(:first-child)
                            margin-top: 20px
                        &:not(:last-child)
                            margin-bottom: 20px
                            padding-bottom: 20px
                            border-bottom: 1px solid #E8F3FF
        &.recs
            margin-bottom: 0
            padding-top: 0
            .sep
                margin-bottom: 28px
            .panels
                display: flex
                column-gap: 14px
                .panel
                    width: 50%
                    border: 1px solid #F1EDE9
                    border-radius: 28px
                    padding: 34px 20px
                    .trigger
                        margin-left: 0
                        margin-bottom: 20px
                        border-radius: 14px
                        img
                            width: 20px
                            height: 20px
                    .descr
                        font-size: 14px
                        font-weight: 300
                        line-height: 20px

        &.promo
            display: flex
            flex-direction: column
            padding-top: 12px
            .title
                margin-bottom: 20px

            .wrapper
                display: flex
                align-items: center
                padding: 40px
                background: #F2F8FF
                border-radius: 28px
            .left
                position: relative
                width: 310px
                margin-right: 60px
                .title
                    margin-bottom: 8px
                    font-size: 20px
                    font-weight: 700
                    line-height: 34px
                .descr
                    font-size: 14px
                    font-weight: 400
                    line-height: 20px
            .right
                display: flex
                flex-grow: 1
                justify-content: center
                .qrcode
                    width: 235px
                    height: 235px
                    background: white
                    border-radius: 28px
                    display: flex
                    justify-content: center
                    align-items: center


    .results
        .result
            display: flex
            justify-content: space-between
            align-items: center
            cursor: pointer
            padding: 20px 14px
            transition: box-shadow $transitionFast
            &:not(:last-child)
                border-bottom: 1px solid #E8F3FF
            .left
                display: flex
                justify-content: flex-start
                align-items: center
                .trigger
                    flex-shrink: 0
                    &-icon
                        width: 34px
                        height: 34px
                        border-radius: 14px
                        margin-left: 0
                        margin-right: 20px
                        img
                            width: 20px
                            height: 20px
                .title
                    margin-bottom: initial
                    font-size: 18px
                    font-weight: 700
                    line-height: 20px
                    color: #4095F2
            .right
                display: flex
                justify-content: flex-end
                align-items: center
                .bar
                    width: 240px
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    .info
                        display: flex
                        justify-content: space-between
                        align-items: center
                        margin-bottom: 8px
                        .tag
                            font-size: 12px
                            font-weight: 400
                            line-height: 20px
                            color: #757575
                    .scale
                        position: relative
                        width: 100%
                        height: 10px
                        border-radius: 4px
                        background: #F2F2F2
                        overflow: hidden
                        .line
                            position: absolute
                            top: 0
                            left: 0
                            height: 100%
                            background: #2AB8AF
                            border-radius: 4px

                    &.high
                        .scale
                            .line
                                background: #2AB8AF
                    &.norm
                        .scale
                            .line
                                background: #2AB8AF
                    &.attention
                        .scale
                            .line
                                background: #FFB82E
                    &.low
                        .scale
                            .line
                                background: #F56D6D






