/////
// Page width for A4 = 714px (96DPI 794px - 80px margin from left and right sides)
/////
.page.page-exercise
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    //margin-top: 160px
    color: #4D4D4D
    background: #ffffff
    .sections
        width: 100%
        max-width: 714px
        padding: 0
    .section
        position: relative
        width: 100%
        background: #FFFFFF
        break-inside: avoid
        margin-bottom: 50px
        &.newpage
            break-before: page
        .heading
            width: 100%
            background: #F2F8FF
            display: flex
            align-items: center
            font-size: 16px
            font-weight: 700
            line-height: 24px
            color: #4095F2
            padding: 16px 40px
            margin-bottom: 32px
        .descr
            position: relative
            font-size: 14px
            font-weight: 400
            line-height: 20px
            color: #4D4D4D
            margin: 16px 0 24px 0
            //white-space: pre-line
            z-index: 1
        .label
            border-radius: 12px
            border: 1px solid #4095F2
            font-size: 14px
            font-weight: 600
            line-height: 20px
            color: #4095F2
            display: inline-block
            justify-content: center
            align-items: center
            padding: 8px 24px
            &.small
                font-weight: 700
                line-height: 24px
                padding: 8px 16px
        .exercise
            width: 100%
            max-width: 100%
            text-align: center
            img
                max-width: 100%
                &:not(:last-child)
                    margin-bottom: 40px



        &.top
            display: flex
            flex-direction: column
            background: #F2F8FF url("/assets/u.svg") no-repeat 100% 50% / 522px auto
            padding: 40px
            margin-bottom: 48px
            .top
                display: flex
                justify-content: space-between
                align-items: center
                width: 100%

                img
                    height: 56px
                .counter
                    display: flex
                    align-items: center
                    justify-content: flex-end
                    span
                        display: block
                        font-size: 14px
                        font-weight: 400
                        line-height: 24px
                        color: #4095F2
                        margin-right: 12px
            .bottom
                .descr
                    color: #4095F2
                    font-size: 14px
                    font-weight: 400
                    line-height: 24px
                    margin-top: 16px
                    margin-bottom: 0






