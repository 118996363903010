#answer
    .task_4_1
        ._images
            display: none
        .image
            height: 300px
            overflow: hidden
            margin-bottom: 40px
            img
                width: 100%
                height: 100%

        .subtask
            display: none
            justify-content: center
            //width: 49vw
            padding-bottom: 0
            +tab
                padding: 0
            +mob
                width: 100%
                padding: 0
                flex-direction: column
                align-items: center
                justify-content: flex-start
            &.active
                display: flex
            .subject
                flex: 50%
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                border-right: 1px solid #4095F2
                padding-right: 120px
                +tab
                    padding-right: 3vw
                +mob
                    flex: 100%
                    border-right: initial
                    padding-right: 0
                    margin-bottom: 24px
                    width: 100%
                img
                    height: 450px
                    +tab
                        height: auto
                        width: 35vw
                    +mob
                        width: 100%
                        height: auto
            .buttons
                padding-left: 120px
                flex: 50%
                display: flex
                flex-direction: column
                justify-content: center
                align-items: flex-end
                +tab
                    padding-left: 3vw
                +mob
                    flex: 100%
                    width: 100%
                    padding: 0
                .wrapper
                    width: 500px
                    text-align: center
                    +tab
                        width: 42vw
                        text-align: center
                    +mob
                        width: 100%
                        display: flex
                        flex-wrap: wrap
                        gap: 13%
                        text-align: initial
                        justify-content: center
                    .button
                        margin: 0 24px 24px 0
                        width: 56px
                        height: 56px
                        border-radius: 16px
                        padding: 0
                        +mob
                            margin: 0
                            margin-bottom: 5%
                            flex: 9%
                            width: 32px
                            height: 32px
                            border-radius: 12px
                            font-size: 14px
                        &:last-child
                            +mob
                                flex-grow: 0
        .pagination
            +tab
                margin-top: 50px
            +mob
                margin-top: 20px