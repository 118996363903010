.page-404
    text-align: center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 700px
    h3
        font-size: 50px
        margin-bottom: 50px
    span
        display: block
        font-size: 20px
        margin-bottom: 50px