#answer
    .task_3_1
        .subtask
            display: none
            justify-content: center
            flex-direction: row
            width: 49vw
            padding-bottom: 0
            +tab
                padding: 0
            +mob
                width: 100%
                padding: 0
                flex-direction: column
            &.active
                display: flex
            .subject
                flex: 50%
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                border-right: 1px solid #4095F2
                font-size: 3vw
                font-weight: 600
                line-height: 88px
                color: #4095F2
                +mob
                    font-size: 32px
                    line-height: 48px
                    border-right: initial
                    border-bottom: 1px solid #4095F2
                    margin-bottom: 40px
                    padding-bottom: 24px
            .buttons
                flex: 50%
                display: flex
                flex-direction: column
                justify-content: center
                align-items: flex-end
                +mob
                    flex: 100%
                    align-items: center
                .button
                    width: 75%
                    height: 72px
                    margin: 0
                    +tab
                        height: 6.5vh
                        height: 6.5dvh
                        border-radius: 16px
                        font-size: 16px
                    +mob
                        width: 100%
                        height: 6.5vh
                        height: 6.5dvh
                        border-radius: 16px
                        font-size: 16px
                    &:not(:last-child)
                        margin-bottom: 16px
                        +mob
                            margin-bottom: 8px
