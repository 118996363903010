.block
    .close
        position: absolute
        top: 20px
        right: 20px
        z-index: 9
        width: 40px
        height: 40px
        border-radius: 12px
        +tab
            width: 40px
            height: 40px
            border-radius: 8px
        +mob
            top: 16px
            right: 16px
            width: 32px
            height: 32px
            border-radius: 8px
        img
            width: 16px
            height: 16px
            transition: 400ms ease-in-out transform
            +tab
                width: 11px
                height: 11px
            +mob
                width: 11px
                height: 11px
        &:hover
            img
                transform: rotate(90deg)
    &.loading
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        height: 100vh
        width: 100vw
        position: absolute
        top: 0
        left: 0
        background: white
        &.active
            display: flex
        img
            width: 50px
            margin-bottom: 10px
    &.orient
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        height: 100vh
        height: 100dvh
        width: 100vw
        position: absolute
        top: 0
        left: 0
        background: white
        z-index: 9999
        &.active
            display: flex
        img
            width: 50px
            margin-bottom: 24px
    &.notification
        display: none
        min-width: 200px
        height: auto
        padding: 20px
        right: 30px
        bottom: 30px
        border-radius: 5px
        box-shadow: 0 0 2px black
        background: #414141
        color: white
        position: fixed
        z-index: 9999
        justify-content: flex-start
        align-items: center
        max-width: 400px
        line-height: 20px
        +mob
            width: calc(100% - 48px)
            right: 24px
        img
            margin-right: 15px
        &.active
            display: flex

    &.outro, &.intro, &.denied
        background: rgb(152 156 173 / 35%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 99
        overflow-y: scroll
        &.active
            display: flex
        .advice
            font-size: 16px
            font-weight: 600
            line-height: 32px
            color: #4095F2
            +mob
                font-size: 14px
                font-weight: 500
                line-height: 20px
        .bullets
            display: flex
            margin-top: 48px
            justify-content: space-around
            align-self: center
            +tab
                display: none
            +mob
                display: none
            span
                width: 16px
                height: 16px
                border-radius: 100%
                display: block
                background: #FFFFFF
                &:not(:last-child)
                    margin-right: 16px
                &.active
                    background: #4095F2
        .modal
            display: flex
            flex-direction: row
            align-items: center
            height: 66vh
            width: 60vw
            min-height: 600px
            padding: 0
            background: white
            border-radius: 32px
            overflow: hidden

            +tab
                width: 80vw
                max-width: 712px
                height: initial
                flex-direction: column
                border-radius: 16px
            +mob
                width: calc(100% - 48px)
                height: initial
                flex-direction: column
                border-radius: 16px
                min-height: initial
            &.state
                display: none
                &.active
                    display: flex
            >.left
                width: 40%
                height: 110%
                overflow: hidden
                display: flex
                justify-content: center
                align-items: flex-start
                +tab
                    width: 100%
                    height: 27vh
                +mob
                    width: 100%
                    height: 27vh
                img
                    height: 100%
                    +tab
                        height: initial
                        width: 100%
                    +mob
                        height: initial
                        width: 100%
            >.right
                width: 60%
                padding: 48px 48px 56px 48px
                +tab
                    width: 100%
                +mob
                    width: 100%
                    padding: 32px 24px 48px 24px
            .supertitle
                font-size: 16px
                font-weight: 400
                line-height: 24px
                color: #4095F2
                margin-bottom: 8px
                display: block
                +mob
                    font-size: 12px
                    line-height: 20px
            h2
                display: flex
                margin-bottom: 24px
                +mob
                    margin-bottom: 16px
            h3
                width: 100%
                font-size: 32px
                font-weight: 700
                line-height: 40px
                color: #000000
                margin-top: 0
                margin-bottom: 24px
                +tab
                    font-size: 32px
                    line-height: 40px
                +mob
                    font-size: 16px
                    line-height: 24px
                    margin-bottom: 8px
            p
                font-size: 20px
                font-weight: 400
                line-height: 32px
                margin-bottom: 24px
                width: 100%
                color: #000000
                +mob
                    font-size: 14px
                    line-height: 24px
                &.small
                    +mob
                        font-size: 12px
                        line-height: 20px
            .sep
                display: block
                width: 100%
                height: 1px
                background: $color-border
                margin-bottom: 20px
            .session
                display: none
                &.active
                    display: block
            .auth
                display: none
                &.active
                    display: block
                .stage
                    display: none
                    margin-bottom: 20px
                    &.active
                        display: block
                    .small
                        margin-bottom: 10px
                    .extrasmall
                        font-size: 14px
                        color: $color-gray-light
                        margin: 0
                        margin-bottom: 17px
                        line-height: 18px
                        &.number
                            span
                                white-space: nowrap
                        &.repeatAfter
                            display: none
                            margin-bottom: 0
                            margin-left: 15px
                            &.active
                                display: block
                        &.repeat
                            display: none
                            margin-bottom: 0
                            margin-left: 15px
                            font-weight: 600
                            color: $color-brand
                            padding: 10px 0
                            cursor: pointer
                            &.active
                                display: block
                    .status
                        color: #F56D6D
                        display: none
                        &.active
                            display: block
                    .form
                        display: flex
                        align-items: center
                        margin-top: 10px
                        margin-bottom: 20px
                        .field
                            width: 200px
                            margin: 0
                            &.error
                                input
                                    border-color: #F56D6D
                            &.code
                                width: 110px
                                +mob
                                    width: 133px
                                input
                                    letter-spacing: 2px
                            input
                                transition: $transitionNorm border
                                border: 1px solid $color-border
                                border-radius: 10px
                        .button
                            width: 56px
                            min-width: initial
                            padding: 0
                            margin: 0
                            margin-left: 5px


            .link
                font-weight: 600
                color: #4095F2
            .button
                +mob
                    width: 100%
                    min-width: 100%
    &.contact.fixed
        position: relative
        padding: 40px 0
        overflow-y: initial
        +mob
            padding: 20px 8px

    &.contact
        background: rgb(152 156 173 / 35%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 99
        overflow-y: scroll
        +mob
            padding: 8px
            //justify-content: flex-start
        &.active
            display: flex

        .modal
            position: relative
            display: flex
            flex-direction: column
            align-items: flex-start
            width: 60vw
            max-width: 1200px
            padding: 24px
            background: white
            border-radius: 24px
            +tab
                width: 80vw
            +mob
                width: 100%
                padding: 16px
            .main
                display: none
                width: 100%
                flex-direction: row
                justify-content: space-between
                +tab
                    flex-direction: column
                    width: 100%
                +mob
                    flex-direction: column
                    width: 100%
                &.active
                    display: flex
                .left
                    position: relative
                    display: flex
                    flex-shrink: 0
                    justify-content: center
                    align-items: center
                    width: 33%
                    background: #4095F2
                    border-radius: 24px
                    overflow: hidden
                    +tab
                        width: 100%
                        background: initial
                    +mob
                        width: 100%
                        background: initial
                    img
                        width: 55%
                        filter: brightness(0) invert(1)
                        +tab
                            filter: initial
                            margin-top: 48px
                            max-width: 306px
                            max-height: 60px
                        +mob
                            filter: initial
                            margin-top: 48px
                            max-width: 366px
                            max-height: 108px

                    &:before
                        content: ''
                        display: block
                        position: absolute
                        top: 0
                        left: 0
                        width: 220px
                        height: 192px
                        background: url('/assets/corner_top.svg') no-repeat 0 0 / cover
                        +tab
                            display: none
                        +mob
                            display: none
                    &:after
                        content: ''
                        display: block
                        position: absolute
                        bottom: 0
                        right: 0
                        width: 192px
                        height: 216px
                        background: url('/assets/corner_bottom.svg') no-repeat 0 0 / cover
                        +tab
                            display: none
                        +mob
                            display: none
                .right
                    width: 67%
                    padding: 0 80px 0 40px
                    +tab
                        width: 100%
                        padding: 0 56px
                    +mob
                        width: 100%
                        padding: 0
                    h3
                        margin-top: 56px
                        margin-bottom: 32px
                        font-size: 24px
                        font-weight: 700
                        line-height: 32px
                        +tab
                            text-align: center
                            margin-top: 48px
                            margin-bottom: 40px
                        +mob
                            text-align: center
                            margin-top: 30px
                            margin-bottom: 30px
                            font-size: 16px
                            font-weight: 500
                            line-height: 24px
                        span
                            color: #4095F2
                            font-size: inherit
                            font-weight: inherit
                            line-height: inherit
                    >span
                        font-size: 16px
                        font-weight: 400
                        line-height: 24px
                        color: #757575
                        margin-bottom: 56px
                        display: inline-block
                        +tab
                            font-size: 12px
                            line-height: 16px
                            margin-bottom: 36px
                        +mob
                            font-size: 12px
                            line-height: 16px
                            margin-bottom: 24px
                        .link
                            color: #4095F2
            .success
                display: none
                position: relative
                flex-direction: column
                flex-shrink: 0
                justify-content: center
                align-items: center
                width: 100%
                height: 60vh
                max-height: 676px
                border-radius: 24px
                overflow: hidden
                margin-right: 56px
                background: #4095F2 url('/assets/corner_close.svg') no-repeat 100% 0 / 74px 74px
                padding: 0 18%
                text-align: center
                &.active
                    display: flex
                +tab
                    height: 500px
                    padding: 24px 65px
                +mob
                    height: 480px
                    padding: 24px
                &:before
                    content: ''
                    display: block
                    position: absolute
                    top: 0
                    left: 0
                    width: 220px
                    height: 192px
                    background: url('/assets/corner_top.svg') no-repeat 0 0 / cover
                    +tab
                        display: none
                    +mob
                        display: none
                &:after
                    content: ''
                    display: block
                    position: absolute
                    bottom: 0
                    right: 0
                    width: 192px
                    height: 216px
                    background: url('/assets/corner_bottom.svg') no-repeat 0 0 / cover
                    +tab
                        display: none
                    +mob
                        display: none
                img
                    margin-bottom: 56px
                    filter: brightness(0) invert(1)
                    max-width: 520px
                    width: 55%
                    +tab
                        //filter: initial
                        //margin-top: 48px
                        max-width: 306px
                        max-height: 60px
                    +mob
                        //filter: initial
                        //margin-top: 48px
                        max-width: 366px
                        max-height: 108px
                h3
                    color: #FFFFFF
                    font-size: 24px
                    font-weight: 500
                    line-height: 32px
                    +mob
                        font-size: 16px
                        line-height: 24px
                        margin-top: 0

    &.qr
        background: rgb(152 156 173 / 35%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 99
        overflow-y: scroll
        &.active
            display: flex
        .modal
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            width: 544px
            padding: 40px 48px 40px 48px
            background: white
            border-radius: 24px
            h3
                +mob
                    font-size: 24px
                    line-height: 32px
            p
                margin-bottom: 24px
                text-align: center
            #qrcode
                width: 200px !important
                height: 200px !important
                margin: 32px 0
            .button
                margin-bottom: 16px
                width: 100%
    &.detailsState, &.details
        background: rgb(152 156 173 / 35%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 99
        overflow-y: scroll
        +mob
            padding: 24px 8px
        &.active
            display: flex
        .wrapper
            position: absolute
            top: 0
            left: 0
            width: 100%
            min-height: 100%
            display: flex
            justify-content: center
            align-items: center
        .modal
            position: relative
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: flex-start
            width: 60vw
            max-width: 1200px
            padding: 40px
            background: white
            border-radius: 24px
            margin: 40px 0
            +tab
                width: 80vw
            +mob
                width: 100%
                padding: 24px 16px 32px
                margin: 20px 0
            h3
                margin-top: 0
                font-size: 32px
                font-weight: 700
                line-height: 46px
                +tab
                    font-size: 24px
                    line-height: 40px
                +mob
                    font-size: 20px
                    line-height: 32px
                    max-width: 290px
            .descr
                font-size: 14px
                font-weight: 400
                line-height: 32px
                color: #4D4D4D
                column-count: 2
                column-gap: 16px
                white-space: pre-line
                +mob
                    font-size: 12px
                    line-height: 20px
                    column-count: initial
            .button
                margin-bottom: 16px
                width: 100%
    &.details
        .modal
            width: 30vw
            max-width: 1000px
            +tab
                width: 80vw
            +mob
                width: 100%
            .descr
                column-count: initial


    &.order
        background: rgb(0 0 0 / 65%)
        backdrop-filter: blur(5px)
        -webkit-backdrop-filter: blur(5px)
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 9999
        overflow-y: scroll
        &.active
            display: flex
        .wrapper
            position: absolute
            top: 0
            left: 0
            width: 100%
            min-height: 100%
            display: flex
            justify-content: center
            align-items: center
            +mob
                padding: 10px
        .modal
            position: relative
            display: flex
            flex-direction: column
            justify-content: flex-start
            max-width: 1480px
            padding: 60px 128px
            background: $color-white
            border-radius: 40px
            +mob
                flex-direction: column
                align-items: flex-start
                padding: 40px 20px
                border-radius: 24px

            h3
                margin-bottom: 30px
            .order-form
                display: flex
                gap: 0 128px
                justify-content: space-between
                +tab
                    gap: 0 45px
                    align-items: flex-start
                +mob
                    gap: 10px 0
                    flex-direction: column
                    align-items: flex-start
                .field
                    position: relative
                    border: 1px solid $color-border
                    border-radius: 16px
                    width: 100%
                .details
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    flex-grow: 1
                    .inner
                        width: 100%
                .review
                    display: flex
                    flex-direction: column-reverse
                    align-items: flex-start
                    width: 450px
                    min-width: 450px
                    +mob
                        width: 100%
                        min-width: 100%
                    .sep
                        width: 100%
                        height: 1px
                        background: $color-border
                        margin: 30px 0
                        &-top
                            margin-bottom: 0
                            +tab
                                display: none
                            +mob
                                display: none
                        &-mob
                            display: none
                            +tab
                                display: none
                            +mob
                                display: none
                    .calltoaction
                        width: 100%
                        .legal
                            font-size: 13px
                            font-weight: 600
                            line-height: 20px
                            text-align: center
                            a
                                color: $color-brand
                    .info
                        width: 100%
                        .promocode
                            position: relative
                            margin-top: 16px
                            input
                                margin: 0
                                text-transform: uppercase
                                &::placeholder
                                    text-transform: none
                            .trigger
                                position: absolute
                                display: none
                                top: 15px
                                right: 15px
                                width: 40px
                                height: 40px
                                border-radius: 12px
                                background-color: $color-brand
                                &:hover
                                    opacity: 0.7
                                +mob
                                    top: 5px
                                    right: 5px
                                &.active
                                    display: flex
                                img
                                    width: 16px
                                    height: 16px
                                    display: none
                                    &:first-child
                                        display: initial
                                    +mob
                                        width: 24px
                                        height: 24px
                                &.filled
                                    background-color: initial
                                    display: flex !important
                                    img
                                        display: none
                                        &:last-child
                                            display: initial

                            .status
                                color: $color-fail
                                margin: 0
                                font-size: 16px
                                font-weight: 500
                                line-height: 15px
                                padding-left: 30px
                                display: none
                                margin-top: 10px
                                +mob
                                    min-height: 24px
                                &.active
                                    display: block
                                &.success
                                    color: $color-success
                                .highlight
                                    display: inline
                                    color: $color-brand
                                    cursor: pointer
                                    &:hover
                                        opacity: .7
                        .line
                            display: flex
                            justify-content: space-between
                            align-items: flex-start
                            &-promo
                                display: none
                                &.active
                                    display: flex
                            .position
                                font-size: 20px
                                font-weight: 500
                                line-height: 30px
                                max-width: 350px
                                +mob
                                    font-size: 16px
                                &.price
                                    font-weight: 600
                                    max-width: initial
                                &.discount
                                    color: $color-success

                        .detalization
                            display: flex
                            flex-direction: column
                            width: 100%
                            gap: 13px 0

                        .total
                            width: 100%
                            .oldprice
                                font-size: 20px
                                font-weight: 500
                                line-height: 24px
                                color: $color-gray
                                text-align: right
                                text-decoration: line-through
                                display: none
                                &.active
                                    display: block

                            .line
                                .position, .price
                                    font-size: 24px
                                    font-weight: 600
                                    line-height: 40px



            span
                margin: 24px 0
                font-size: 24px
                font-weight: 500
                line-height: 40px
                display: block
            .subtitle
                +mob
                    font-weight: 600
            input, textarea
                height: 70px
                outline: none
                border-radius: 16px
                border: 1px solid $color-border
                padding: 10px 30px
                font-size: 20px
                font-weight: 500
                line-height: 40px
                width: 100%
                margin-bottom: 24px
                +mob
                    font-size: 16px
                    line-height: 24px
                    height: 50px
            textarea
                height: 120px
                max-width: 100%
                min-width: 100%
                margin-bottom: 0
                font-size: 20px
                font-weight: 500
                line-height: 25px
                font-family: Arial
                padding-top: 18px
            .alien
                position: relative
                margin-bottom: 24px
            .gift
                position: relative
            .checkbox
                position: absolute
                z-index: -1
                opacity: 0
            .checkbox+label
                display: inline-flex
                align-items: center
                font-size: 20px
                font-weight: 500
                line-height: 48px
                width: 100%
                padding: 10px 30px
                cursor: pointer
                +mob
                    font-size: 16px
                    line-height: 24px
            .checkbox+label::before
                content: ''
                display: inline-block
                position: relative
                top: 1px
                width: 25px
                height: 25px
                flex-shrink: 0
                flex-grow: 0
                border: 1px solid $color-border
                border-radius: 6px
                margin-right: 16px
                background-color: $color-white
                background-repeat: no-repeat
                background-position: center center
                background-size: auto
                cursor: pointer
                transition: $transitionNorm background-color

            .checkbox:checked+label::before
                background-color: $color-white
                background-image: url('/assets/icon-check.svg')
                background-size: 90%
            .gift-wrapper
                display: none
                margin-top: 24px
                &.active
                    display: block

            .button
                margin-top: 30px
                margin-bottom: 16px
                width: 100%
                padding: 20px 48px
                display: inline-flex
                justify-content: center
                align-items: center
                border-radius: 16px
                background: $color-brand
                color: $color-white
                font-size: 24px
                font-weight: 700
                line-height: 40px
                letter-spacing: 0.24px

                transition: $transitionNorm opacity, $transitionNorm background-color
                cursor: pointer
                user-select: none
                height: initial
                +tab
                    padding: 24px 48px
                    font-size: 24px
                    line-height: 40px
                    border-radius: 16px
                +mob
                    padding: 8px 48px
                    font-size: 16px
                    line-height: 40px
                    border-radius: 12px

