.form
    .field
        position: relative
        margin-bottom: 32px
        +tab
            margin-bottom: 16px
        +mob
            margin-bottom: 16px
        .placeholder
            position: absolute
            display: none
            top: 16px
            left: 20px
            font-size: 16px
            font-weight: 400
            line-height: 24px
            color: #757575
            pointer-events: none
            &.active
                display: block
        input
            background: none
            border: none
            border-bottom: 1px solid #F3F3F4
            padding: 0 20px
            width: 100%
            height: 56px
            font-family: 'Montserrat', sans-serif
            font-size: 16px
            font-weight: 400
            line-height: 24px
            color: #757575
            letter-spacing: 0
            transition: $transitionNorm border-bottom-color
        &.phone
            input
                letter-spacing: 1px
        &.error
            input
                border-bottom-color: #F56D6D
    .button
        width: 315px
        margin-top: 16px
        margin-bottom: 24px
        border-radius: 12px
        font-size: 16px
        font-weight: 500
        max-width: 100%
        +tab
            width: 100%
        +mob
            width: 100%
        &.blue
            +mob
                font-size: 16px
.checkbox
    position: absolute
    z-index: -1
    opacity: 0
.checkbox+label
    display: inline-flex
    align-items: flex-start
    font-size: 14px
    font-weight: 500
    line-height: 20px
    margin-top: 8px
    margin-bottom: 32px
    +mob
        font-size: 12px
        font-weight: 400
        line-height: 20px
        margin-top: 0
        margin-bottom: 16px
.checkbox+label::before
    content: ''
    display: inline-block
    position: relative
    top: 6px
    width: 24px
    height: 24px
    flex-shrink: 0
    flex-grow: 0
    border: 1px solid #4095F2
    border-radius: 4px
    margin-right: 16px
    background-color: #FFFFFF
    background-repeat: no-repeat
    background-position: center center
    background-size: auto
    cursor: pointer
    transition: $transitionNorm background-color

.checkbox:checked+label::before
    background-color: #4095F2
    background-image: url('/assets/check.svg')