.page-question
    .main
        display: flex
        flex-direction: column
        justify-content: space-between
        min-height: calc(100vw / 1.99) !important // proportion from standard screen ratio

        +tab
            min-height: initial
        +mob
            min-height: initial
        &.pc
            height: 100vh
        //height: 100vh
        //height: 100dvh
        //#question-content
        .question
            width: 100%
            min-width: 500px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            position: relative
            height: 100%
            transition: $transitionNorm top, $transitionNorm transform
            padding: 0 40px 48px 40px
            //overflow: hidden
            +tab
                overflow: hidden
            +mob
                padding: 0 24px 24px 24px
                min-width: 100%
                overflow: hidden
                //justify-content: flex-start
            &.active
                #answer
                    opacity: 1
                    .pagination
                        opacity: 1
                .general
                    width: 45vw
                    border: initial
                    border-radius: initial
                    border-bottom: 1px solid #DFEBFB
                    background: none
                    padding: 0 20px 20px 20px
                    top: 0
                    transform: translateY(0)
                    +mob
                        width: 100%
                        margin-bottom: 24px
                        padding: 0 16px 16px 16px
                    .line
                        opacity: 0
                        margin-bottom: 0
                        margin-top: 0
                        img
                            transform: scale(0)
                    .title
                        font-size: 20px
                        left: 50%
                        transform: translateX(-50%)
                        +tab
                            font-size: 17px
                            //line-height: 24px
                        +mob
                            font-size: 14px
                            line-height: 24px
                    .subtitle
                        opacity: 0
            .general
                position: relative
                background: #F2F8FF
                border: 1px solid #DFEBFB
                border-radius: 24px
                padding: 40px 40px 40px 40px
                //top: 50%
                //transform: translateY(-50%)
                width: 35vw
                transition: $transitionNorm all
                margin: 0
                margin-bottom: 40px
                +tab
                    width: 45vw
                +mob
                    width: 100%
                    padding: 80px 16px 24px 16px
                    margin-bottom: 0
                .line
                    position: relative
                    width: 100%
                    height: 1px
                    background: #D2D2D2
                    margin-top: 88px
                    margin-bottom: 32px
                    opacity: 1
                    transition: $transitionNorm opacity, $transitionNorm margin-bottom, $transitionNorm margin-top
                    +mob
                        margin-top: 0
                        margin-bottom: 16px
                    img
                        position: absolute
                        width: 192px
                        height: 224px
                        bottom: 1px
                        left: 0
                        transform-origin: bottom left
                        transition: $transitionNorm transform
                        +tab
                            width: 150px
                            height: initial
                        +mob
                            width: 102px
                            height: initial
                    .subtitle
                        position: absolute
                        bottom: 40px
                        right: 0
                        font-size: 24px
                        font-weight: 600
                        line-height: 40px
                        color: #4095F2
                        transition: $transitionNorm opacity
                        +tab
                            font-size: 19px
                        +mob
                            font-size: 14px
                            line-height: 16px
                            bottom: 30px
                        span
                            color: inherit
                            font-size: inherit
                            font-weight: inherit
                            line-height: inherit
                .title
                    font-size: 24px
                    font-weight: 600
                    line-height: 32px
                    color: #757575
                    transition: $transitionNorm font-size, $transitionNorm left, $transitionNorm transform
                    position: relative
                    display: inline-block
                    left: 0
                    +tab
                        text-align: center
                        font-size: 19px
                        line-height: 27px
                    +mob
                        font-size: 14px
                        line-height: 24px
                        color: #595959
                        width: 100%
                        text-align: center
                    span
                        color: #4095F2
                        font-size: inherit
                        font-weight: inherit
                        line-height: inherit
                .type
                    display: block

            //.success
            //    position: absolute
            //    bottom: 48px
            //    left: 50%
            //    transform: translateX(-50%)
            //    display: flex
            //    flex-direction: column
            //    justify-content: center
            //    align-items: center
            //    opacity: 0
            //    pointer-events: none
            //    transition: $transitionNorm opacity
            //    span
            //        font-size: 16px
            //        font-weight: 600
            //        line-height: 24px
            //        color: #2AB8AF
            //        display: block
            //        margin-top: 10px

            &.done
                .morph
                    opacity: 0
                //.success
                //    opacity: 1

        .footer
            height: 128px
            padding: 40px 70px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            position: fixed
            bottom: 0
            width: 100%
            +tab
                height: 96px
                min-height: 96px
            +mob
                //height: 8vh
                //height: 8dvh
                //min-height: 8vh
                //min-height: 8dvh
                height: 55px
                min-height: 55px
                padding: 0 24px
            &.action
                +mob
                    justify-content: center
                .button.blue
                    +mob
                        font-size: 12px
                        height: 40px
                        border-radius: 12px
                .record
                    +mob
                        display: none
            .button
                opacity: 0
                pointer-events: none
                +mob
                    &:active
                        opacity: 1
                    &:hover
                        .trigger
                            background-color: initial
                            transform: initial
                &.active
                    opacity: inherit
                    pointer-events: initial
                .trigger
                    width: 40px
                    height: 40px
                    border-radius: 12px
                    +tab
                        width: 32px
                        height: 32px
                        border-radius: 8px
                    +mob
                        width: 32px
                        height: 32px
                        border-radius: 8px
                    img
                        width: initial
                        height: initial
                        +tab
                            width: 11px
                            height: 11px
                        +mob
                            width: 11px
                            height: 11px


            .record
                opacity: 0
                display: flex
                justify-content: flex-start
                align-items: center
                transition: $transitionNorm opacity
                img
                    margin-right: 16px
                .icon
                    position: relative
                    width: 18px
                    height: 18px
                    background-color: #F56D6D
                    border-radius: 100%
                    margin-right: 16px
                    &:after
                        content: ''
                        display: block
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                        width: 100%
                        height: 100%
                        animation: blink 1500ms linear infinite
                        border: 1px solid #F56D6D
                        border-radius: 100%
                        transform-origin: center
                span
                    font-size: 16px
                    font-weight: 400
                    line-height: 24px
                    color: #757575
                &.active
                    opacity: 1
                    +mob
                        opacity: 0
