.page .footer
    width: 100%
    padding: 48px 0 56px
    background: #FFFFFF
    color: #4D4D4D
    display: flex
    flex-direction: column
    align-items: center
    +tab
        padding: 48px 0 56px
    +mob
        padding: 40px 0 72px
    .inner
        width: 100%
        max-width: 1136px
        padding: 0
        +tab
            padding: 0 36px
        +mob
            padding: 0 24px
    .wrapper
        display: flex
        justify-content: space-between
        align-items: center
        +mob
            flex-direction: column
            align-items: flex-start
    .logo
        display: block
        margin-bottom: 32px
    span
        display: block
        font-size: 14px
        font-weight: 400
        line-height: 20px
        //color: #FFFFFF
        &.line
            margin-bottom: 32px
            a
                &.link
                    font-weight: 600
                    text-decoration: underline
                    color: inherit
                    +mob
                        display: block
                        margin-bottom: 8px
                &:not(:last-child)
                    margin-right: 16px
        &.subtitle
            font-size: 16px
            font-weight: 600
            line-height: 32px
            margin-bottom: 16px
        &.legal_year
            display: inline-block
    .right
        display: flex
        flex-direction: column
        align-items: flex-end
        +mob
            align-items: flex-start
        .social
            padding: 10px 0 10px 10px
            margin-bottom: 46px
            +mob
                padding: 0
                margin-bottom: 32px
    .copyright
        font-size: 14px
        font-weight: 400
        line-height: 20px
        +mob
            padding-top: 32px