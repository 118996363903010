#answer
    .task_2_1
        .task
            flex-wrap: initial
            padding-bottom: 160px
            +tab
                padding: 0
            +mob
                flex-wrap: wrap
                gap: 0 5%
                padding-bottom: 0
                justify-content: center
            .ph-wrapper
                width: 160px
                height: 160px
                +tab
                    width: 120px
                    height: 120px
                +mob
                    width: 96px
                    height: 26vw
                    flex: 30%
                    flex-grow: 0
                    margin-bottom: 5%
                &:nth-last-child(-n + 2)
                    +mob
                        margin-bottom: 0
                .placeholder
                    border-radius: 48px
                    +tab
                        border-radius: 40px
                        font-size: 20px
                    +mob
                        border-radius: 32px