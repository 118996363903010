#answer
    .task_7_1
        .task
            display: flex
            flex-wrap: wrap
            width: 40vw
            +tab
                padding: 0
                width: 60vw
                gap: 3%
            +mob
                width: 100%
                gap: 3%
            .color
                width: 100px
                height: 150px
                flex: 20%
                border: 1px solid #A6A6A6
                border-radius: 24px
                cursor: pointer
                margin: 10px
                //box-shadow: 0 0 3px 0
                transition: $transitionNorm background-color, $transitionNorm border-color
                +tab
                    margin: 0
                    margin-bottom: 3%
                +mob
                    flex: 41%
                    margin: 0
                    width: 50%
                    height: 13vh
                    height: 13dvh
                    margin-bottom: 3%
                    border-radius: 16px
                &.active
                    background-color: #ffffff !important
                    border: 1px solid #4095F2
                    box-shadow: none
                    cursor: initial