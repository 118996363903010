#answer
    .task_6_1
        .task
            width: 65vw
            justify-content: center
            +tab
                padding: 0
            +mob
                width: 100%
                gap: 0 2%
                margin-bottom: 14vh
                margin-bottom: 14dvh
            .ph-wrapper
                width: 96px
                height: 96px
                margin: 0 5px
                flex: initial
                min-width: initial
                +mob
                    margin: 0
                    width: 72px
                    height: 20.5vw
                    flex: 23.5%
                    margin-bottom: 16px
                &:nth-last-child(-n + 2)
                    +mob
                        margin-bottom: 0
                        flex-grow: 0
                .placeholder
                    border-radius: 32px
                    font-size: 48px
                    font-weight: 500
                    color: #4095F2
                    +tab
                        font-size: 36px
                        border-radius: 24px
                    +mob
                        border-radius: 16px
                        font-size: 16px
                &.active
                    .placeholder
                        border-width: 3px
                &.on
                    .placeholder
                        background-color: #F2F8FF
                        border: 1px solid #4095F2
                &.off
                    .placeholder
                        background-color: #F2F8FF

                &.correct
                    .placeholder
                        background: #F2F8FF
                        box-shadow: 0 0 5px 0 #F2F8FF
                        animation: ripple-green 500ms ease-in-out forwards
                &.wrong
                    .placeholder
                        background: #F2F8FF
                        box-shadow: 0 0 5px 0 #F2F8FF
                        animation: ripple-red 500ms ease-in-out forwards
        .button
            display: none
            width: calc(100% - 48px)
            height: 64px
            font-size: 16px
            position: absolute
            bottom: 55px
            opacity: 1
            &.taped
                animation: $transitionNorm tap
            +tab
                display: inline-flex
                width: 65vw
                &:active
                    opacity: 1
                &:hover
                    opacity: 1
            +mob
                display: inline-flex
                &:active
                    opacity: 1
                &:hover
                    opacity: 1
            b
                font-weight: 700