.trigger
    margin-left: 16px
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    border: 1px solid $color-brand
    border-radius: 16px
    transition: $transitionNorm transform, $transitionNorm background-color
    cursor: pointer
    +mob
        width: 40px
        height: 40px
        border-radius: 16px
    img
        width: 32px
        height: 32px
        +mob
            width: 24px
            height: 24px

.switch
    margin-left: 16px
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    border: 1px solid $color-brand
    border-radius: 16px
    transition: $transitionNorm transform, $transitionNorm background-color
    cursor: pointer
    +mob
        width: 40px
        height: 40px
        border-radius: 16px
    img
        width: 32px
        height: 32px
        +mob
            width: 24px
            height: 24px
.button
    height: 56px
    border-radius: 16px
    display: inline-flex
    justify-content: center
    align-items: center
    padding: 0 32px
    outline: none
    font-size: 20px
    font-weight: 500
    line-height: 32px
    transition: $transitionNorm opacity, $transitionNorm background-color
    cursor: pointer
    user-select: none
    &:hover
        opacity: 0.7
        &.hollow
            opacity: 1
            .trigger
                background-color: #EFF7FF
                transform: translateX(10px)
    &:active
        opacity: 0.6
    &.blue
        background: #4095F2
        color: #FFFFFF
        font-size: 16px
        font-weight: 500
        line-height: 40px
        min-width: 208px
        +tab
            font-size: 14px
        +mob
            font-size: 14px
    &.white
        background: #FFFFFF
        color: #4095F2
        border: 1px solid #4095F2
        font-size: 16px
        font-weight: 500
        line-height: 40px
        +tab
            font-size: 14px
        +mob
            font-size: 14px
    &.hollow
        background: initial
        padding: 0
        span
            font-size: 16px
            font-weight: 500
            line-height: 40px
            color: #4095F2
            display: block
            +tab
                font-size: 14px
            +mob
                font-size: 14px
    &.small
        font-size: 16px
        +tab
            font-size: 14px
        +mob
            font-size: 14px
    &.option
        height: 80px
        border-radius: 24px
        border: 1px solid #4095F2
        background: #ffffff
        font-size: 24px
        font-weight: 600
        color: #717171
        &.active
            color: #4095F2
            background-color: #F2F8FF
    &.disabled
        background-color: #d2d2d2
        opacity: 0.7
        cursor: default
        &.blue
            background-color: #B4D4F6
    >img
        margin-left: 15px
        &.left
            margin-left: initial
            margin-right: 15px