#answer
    .task_1_1, .task_8_1
        .task
            width: 69vw
            padding-bottom: 160px
            +tab
                padding: 0
            +mob
                width: 100%
                padding: 0
                gap: 8px
            .ph-wrapper
                height: 98px
                +tab
                    height: 80px
                +mob
                    height: 9.5vh
                    height: 9.5dvh