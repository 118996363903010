.page-contact
    .header
        height: 96px
        background: #FFFFFF
        +tab
            margin-bottom: 0
        +mob
            height: 64px
            margin-bottom: 0
        .logo
            top: 31px
            +mob
                top: 16px
            img
                height: 32px



