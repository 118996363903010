.header
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    padding: 40px 70px
    +tab
        padding: 30px 5vw
    +mob
        //padding: 24px 5vw
        padding: 0
        height: 55px
        min-height: 55px
    .logo
        position: absolute
        left: 70px
        top: 38px
        +tab
            left: 5vw
            top: 28px
        +mob
            left: 24px
            top: 16px
        .img
            width: 157px
            height: 40px
            background: url('/assets/logo.svg') no-repeat 50% 50% / cover
            +mob
                width: 24px
                height: 24px
                background-image: url('/assets/logo_small.svg')
    .progress
        width: 50%
        +mob
            width: 58vw
        .counter
            display: block
            color: #595959
            padding-bottom: 8px
            +mob
                display: none
        .bar-wrapper
            position: relative
            overflow: hidden
            width: 100%
            height: 16px
            border-radius: 24px
            background: #EBEBEB
            +mob
                height: 8px
            .bar
                position: absolute
                top: 0
                left: 0
                width: 0
                height: 100%
                border-radius: 24px
                background: #B9D7FF
                transition: $transitionNorm width
    .timer
        border: 1px solid #B9D7FF
        border-radius: 24px
        background: #FFFFFF
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        padding: 24px 32px
        img
            display: block
            margin-right: 14px
        .time
            font-size: 24px
            line-height: 24px
            font-weight: 500
            color: #595959