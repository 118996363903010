@import 'mixin'
@import 'variables'
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap')

*, *:before, *:after
    box-sizing: border-box

*:focus
    outline: none

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
    margin: 0
    padding: 0
    border: 0
    font-size: 100%
    font: inherit
    user-select: none
    -webkit-user-select: none
    vertical-align: baseline
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    text-size-adjust: none
    -webkit-tap-highlight-color: transparent

footer, header, nav, section, main
    display: block

body
    line-height: 1
    user-select: none

ol, ul
    list-style: none

blockquote, q
    quotes: none

blockquote:before, blockquote:after, q:before, q:after
    content: ''
    content: none

table
    border-collapse: collapse
    border-spacing: 0

input
    -webkit-appearance: none
    border-radius: 0

body
    background: $color-white
    font-family: 'Montserrat', sans-serif
    font-weight: 400
    color: $color-black
    width: 100%
    margin: 0
    padding: 0
    font-size: 13px
    touch-action: pan-x pan-y

a, a:hover, a:visited, a:active
    outline: none
    text-decoration: none
    color: initial
h2
    display: block
    font-size: 80px
    line-height: 88px
    font-weight: 800
    color: #4095F2
    z-index: 1
    +tab
        font-size: 74px
        line-height: 80px
    +mob
        font-size: 48px
        line-height: 56px

h3
    margin-top: 28px
    display: block
    font-size: 32px
    line-height: 48px
    font-weight: 600
    margin-bottom: 16px
    color: #3E3E3E
    +tab
        font-size: 24px
        line-height: 32px
    +mob
        font-size: 16px
        font-weight: 500
        line-height: 26px
h4
    display: block
    font-size: 24px
    line-height: 32px
    font-weight: 500
    color: #4D4D4D
    margin-bottom: 24px
    +tab
        font-size: 20px
        line-height: 32px
    +mob
        font-size: 16px
        font-weight: 600
        line-height: 32px
p
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: #757575
    +tab
        font-size: 14px
        line-height: 20px

@import 'animations'
@import 'components/block'
@import 'components/form'
@import 'components/header'
@import 'components/footer'
@import 'components/answer'
@import 'components/button'
@import 'components/morph'
@import '404'
@import 'question'
@import 'terms'
@import 'contact'
@import 'results'
@import 'pdf'
@import 'exercise'
@import 'questions/task_1_1'
@import 'questions/task_2_1'
@import 'questions/task_2_2'
@import 'questions/task_3_1'
@import 'questions/task_4_1'
@import 'questions/task_5_1'
@import 'questions/task_6_1'
@import 'questions/task_7_1'
@import 'questions/task_9_1'