.page-terms
    .sections
        width: 100%
        padding: 0 13vw
        +tab
            padding: 0 5vw
        +mob
            padding: 0 24px
        .section
            position: relative
            width: 100%
            background: #FFFFFF
            box-shadow: 0 0 20px rgba(163, 194, 255, 0.1)
            border-radius: 32px
            padding: 80px 112px
            margin-bottom: 40px
            overflow: hidden
            +tab
                padding: 50px 60px
            +mob
                padding: 32px 24px
                border-radius: 24px