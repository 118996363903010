#answer
    opacity: 0
    transition: $transitionNorm opacity
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    //width: 45vw
    overflow: visible !important
    +mob
        width: 100%
    .answer
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        +mob
            width: 100%
        .task
            display: flex
            flex-wrap: wrap
            position: relative
            border-radius: 24px
            padding: 40px
            +mob
                width: 100%
                padding: 0
            .ph-wrapper
                max-width: 300px
                height: 80px
                display: flex
                justify-content: center
                align-items: center
                flex: 20%
                overflow: hidden
                +mob
                    flex: calc(50% - 8px)
                &.done
                    .placeholder
                        border-color: #03C8BC
                        &:after
                            opacity: 1
            .placeholder
                position: relative
                display: flex
                width: 90%
                height: 90%
                border: 1px dashed #4095F2
                border-radius: 24px
                color: #4095F2
                font-weight: 500
                font-size: 20px
                justify-content: center
                align-items: center
                text-transform: capitalize
                overflow: hidden
                transition: $transitionNorm border-color
                padding: 0 15px
                +tab
                    border-radius: 16px
                    font-size: 16px
                    line-height: 24px
                    font-weight: 500
                    padding: 0 5px
                +mob
                    width: 100%
                    height: 100%
                    border-radius: 16px
                    font-size: 16px
                    line-height: 24px
                    font-weight: 500
                    padding: 0 5px
                &:after
                    content: ''
                    display: block
                    position: absolute
                    width: 100%
                    height: 100%
                    top: 0
                    left: 0
                    background: #EFFFFE url("/assets/success.svg") no-repeat 50% 50% / 33px
                    transition: $transitionNorm opacity
                    border-radius: inherit
                    opacity: 0
                    z-index: 1
                &.filled
                    background-color: #F2F8FF
                span
                    word-wrap: break-word
                    display: inline-block
                    width: 100%
                    text-align: center
                    +tab
                        line-height: 15px
                    +mob
                        line-height: 15px
        .subtask
            padding: 40px
        input
            width: 100%
            height: 80px
            border-radius: 24px
            padding: 24px
            border: 1px solid #4095F2
            font-weight: 500
            font-size: 24px
            color: #757575
            margin-bottom: 10px
            text-align: center
            &.invalidated
                border: 1px solid #f24051
            &.ph
                border: 1px dashed #4095F2
                &:disabled
                    background: white

        .pagination
            margin-top: 64px
            display: flex
            justify-content: space-around
            align-self: center
            +tab
                margin-top: 50px
            +mob
                margin-top: 50px
            span
                width: 16px
                height: 16px
                border-radius: 100%
                display: block
                background: #BBDAFC
                +tab
                    width: 12px
                    height: 12px
                +mob
                    width: 8px
                    height: 8px
                &:not(:last-child)
                    margin-right: 16px
                &.active
                    background: #4095F2

        .success
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            //backdrop-filter: blur(10px)
            background-color: #fcfcfc
            opacity: 0
            pointer-events: none
            transition: $transitionNorm opacity
            z-index: 9
            &.active
                opacity: 1
            span
                font-size: 16px
                font-weight: 600
                line-height: 24px
                color: #2AB8AF
                display: block
                margin-top: 10px