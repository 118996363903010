#answer
    .task_5_1
        width: 65vw
        text-align: center
        +tab

        +mob
            width: 100%
            justify-content: flex-start
        .timer
            font-size: 16px
            font-weight: 600
            line-height: 32px
            color: #757575
            margin: 20px 0 20px 0
            +tab
                margin-top: 0
            +mob
                margin-top: 0
        .task
            width: 70vw
            +tab
                padding: 0
            +mob
                width: 100%
                gap: 0 2%
                justify-content: center
            .ph-wrapper
                +tab
                    height: 6vh
                    height: 6dvh
                +mob
                    flex: 32%
                    height: 5.5vh
                    height: 5.5dvh
                    margin-bottom: 2%
                &:nth-last-child(-n + 2)
                    +mob
                        margin-bottom: 0
                        flex-grow: 0
        .morph
            +tab
                margin-top: 30px
            +mob
                margin-top: 30px