$color-brand: #4095F2
$color-brand-secondary: #68a9f4
$color-white: #FAFAFA
$color-black: #595959
$color-secondary: #7a7a7a
$color-bg: #1c232b
$color-yellow: #fcd434
$color-gray-light: #848b95
$color-gray-dark: #333b46
$color-border: #848b9570
$color-sell: #6ec88b
$color-buy: #6e81c8
$color-positive: #2ebd85
$color-negative: #f6465d
$color-light-blue: #F2F8FF
$color-bg-gray: #FCFCFC
$color-gray: #CACACA
$color-fail: #F56D6D
$color-success: #2AB8AF

$transitionFast: 200ms ease-in-out
$transitionNorm: 400ms ease-in-out
